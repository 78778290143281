import { getPie, getMap } from "@/api/report";
const actions = {
  getPie({ commit }, params) {
    console.log(params);
    return new Promise((resolve, reject) => {
      getPie(params)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMap({ commit }, data) {
    return new Promise((resolve, reject) => {
      getMap(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
