import {
  getinfo,
  getlist,
  getQyInfo,
  getdetail,
  searchinfo,
  getbanner,
} from '@/api/index/index';
const actions = {
  getinfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getinfo(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getdetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      getdetail(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getbanner({ commit }, data) {
    return new Promise((resolve, reject) => {
      getbanner(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getlist({ commit }, data) {
    return new Promise((resolve, reject) => {
      getlist(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getQyInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getQyInfo(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  searchinfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      searchinfo(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
