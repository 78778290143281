import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/global.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './assets/iconfont/iconfont.css';
import { ElMessage } from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import cache from '@/utils/cache';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import lazyPlugin from 'vue3-lazy';
import 'swiper/swiper.min.css';
import 'animate.css';
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(ElementPlus, {
  locale: zhCn,
});
app.use(store).use(ElementPlus).use(router).mount('#app');
router.afterEach(() => {
  window.scrollTo(0, 0);
});
