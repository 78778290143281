import request from '@/utils/request';
export function queryNewsPage(e) {
  return request({
    url: '/api/article/f/getByType/' + e.id,
    method: 'GET',
    params: e.data,
  });
}
export function queryArticleAllPage(params) {
  return request({
    url: '/api/article/f/getByType',
    method: 'GET',
    params: params,
  });
}
export function addNews(data) {
  return request({
    url: '/api/article',
    method: 'POST',
    data: data,
  });
}
export function queryNewsById(id) {
  return request({
    url: '/api/article/f/getById/' + id,
    method: 'GET',
  });
}
export function editNews(data) {
  return request({
    url: '/api/article',
    method: 'PUT',
    data: data,
  });
}
export function deleteNews(id) {
  return request({
    url: '/api/article/' + id,
    method: 'DELETE',
  });
}
