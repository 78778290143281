import request from "@/utils/request";
export function getPie(params) {
  return request({
    url: "/api/visit/pie",
    method: "get",
    params,
  });
}
export function getMap() {
  return request({
    url: "/api/visit/map",
    method: "get",
  });
}
