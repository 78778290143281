import { putinfo, getinfo, queryNewsPageIndex } from '@/api/Lnformation/info';
const actions = {
  putinfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      putinfo(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getinfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getinfo(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryNewsPageIndex({ commit }, data) {
    return new Promise((resolve, reject) => {
      queryNewsPageIndex(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
