import request from '@/utils/request';

export function getinfo(params) {
  return request({
    url: '/api/frontEnd/index',
    method: 'get',
    params,
  });
}
export function searchinfo(params) {
  return request({
    url: '/api/frontEnd/search',
    method: 'get',
    params,
  });
}
export function getlist(params) {
  return request({
    url: '/api/frontEnd/list/' + params.typeName,
    method: 'get',
    params,
  });
}

export function getQyInfo(params) {
  return request({
    url: '/api/frontEnd/culture/' + params.typeName,
    method: 'get',
    params,
  });
}

export function getdetail(params) {
  return request({
    url: '/api/frontEnd/detail/' + params,
    method: 'get',
  });
}
export function getbanner(params) {
  return request({
    url: '/api/frontEnd/banner/' + params,
    method: 'get',
  });
}
