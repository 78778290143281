<template>
  <div class="menu">
    <ul style="" class="topNav">
      <li
        v-for="(v, i) in routeArr"
        :key="i"
        class="navItem"
        @mouseenter="enterNav(v, i)"
        @mouseleave="leaveNav(v, i)"
        :class="currentShowLight == i ? 'bg0b64be' : ''"
      >
        <!-- :style="{ backgroundColor: currentShowLight == i ? '#2270ac' : '' }" -->
        <router-link :to="{ path: v.path }" class="liBox">
          {{ v.name }}
        </router-link>
      </li>
    </ul>
    <!-- :class="topStyleShow ? 'bg0b64be' : ''" -->
    <!-- 二级菜单 -->
    <div
      class="bigBox"
      v-if="showTwo"
      @mouseenter="enterTwoNav"
      @mouseleave="leaverNav"
    >
      <div class="twoBox">
        <ul class="twoUlStyle">
          <li v-for="(e, c) in twoShowRouteArr" :key="c">
            <div class="twoLiStyle" @click="gotoLink(e)">
              <!-- <router-link :to="{ path: e.path }">{{ e.name }}</router-link> -->
              {{ e.name }}
            </div>
          </li>
        </ul>
        <div class="twoBoxRightContent" v-if="twoNavShow == 1">
          <span style="margin-bottom: 30px"
            >攀枝花发展（控股）集团有限责任公司，2022年5月重组成立，是攀枝花市属一级监管企业，注册资本150亿元，聚焦资本运营、股权投资。</span
          >
          <img
            src="@/assets/imgs/topTwoNav.png"
            style="width: 616px; height: 185px"
            alt=""
          />
        </div>
        <div class="twoBoxRightContent" v-if="twoNavShow == 2">
          <span style="margin-bottom: 30px"
            >攀枝花发展（控股）集团有限责任公司，2022年5月重组成立，是攀枝花市属一级监管企业，注册资本150亿元，聚焦资本运营、股权投资。</span
          >
          <img
            src="@/assets/imgs/topTwoNav.png"
            style="width: 616px; height: 185px"
            alt=""
          />
        </div>
        <div class="twoBoxRightContent" v-if="twoNavShow == 4">
          <span style="margin-bottom: 30px"
            >攀枝花发展（控股）集团有限责任公司，2022年5月重组成立，是攀枝花市属一级监管企业，注册资本150亿元，聚焦资本运营、股权投资。</span
          >
          <img
            src="@/assets/imgs/xwdtTopImg.png"
            style="width: 616px; height: 185px; opacity: 1"
            alt=""
          />
        </div>
        <div class="twoBoxRightContent" v-if="twoNavShow == 5">
          <span style="margin-bottom: 30px"
            >攀枝花发展（控股）集团有限责任公司，2022年5月重组成立，是攀枝花市属一级监管企业，注册资本150亿元，聚焦资本运营、股权投资。</span
          >
          <img
            src="@/assets/imgs/xwdtTopImg.png"
            style="width: 616px; height: 185px; opacity: 1"
            alt=""
          />
        </div>
        <!--   <div class="twoBoxRightContent" v-if="twoNavShow == 6">
          <span style="margin-bottom: 30px"
            >攀枝花发展（控股）集团有限责任公司，2022年5月重组成立，是攀枝花市属一级监管企业，注册资本150亿元，聚焦资本运营、股权投资。</span
          >
          <img
            src="@/assets/imgs/zhfz1.png"
            style="width: 616px; height: 185px"
            alt=""
          />
        </div> -->
        <div class="twoBoxRightContent" v-if="twoNavShow == 7">
          <span style="margin-bottom: 30px"
            >攀枝花发展（控股）集团有限责任公司，2022年5月重组成立，是攀枝花市属一级监管企业，注册资本150亿元，聚焦资本运营、股权投资。</span
          >
          <img
            src="@/assets/imgs/zhfz1.png"
            style="width: 616px; height: 185px"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
const store = useStore();
const route = useRoute();
const activeIndex = ref('/');
const twoNavShow = ref(0);
const twoName = ref();
const currentShowLight = ref();
const currentLeaveShow = ref();

const showTwo = ref(false);
const topStyleShow = ref(false);
const router = useRouter();
const routeArr = reactive([]);
const twoRouteArr = reactive([]);
const twoShowRouteArr = reactive([]);
router.options.routes.forEach((v) => {
  if (v.children) {
    v.children.forEach((item) => {
      if (!item.hidden) {
        routeArr.push(item);
      }
      if (item.children && item.children.length !== 0) {
        twoRouteArr.push(item);
      }
    });
  }
});

function enterNav(v, i) {
  twoShowRouteArr.length = 0;
  twoNavShow.value = i;
  currentShowLight.value = i;
  twoName.value = v.name;
  if (v.children.length > 0) {
    showTwo.value = true;
    v.children.forEach((v) => {
      twoShowRouteArr.push(v);
    });
  } else {
    showTwo.value = false;
  }
}
function leaveNav(v, i) {
  currentShowLight.value = null;
  currentLeaveShow.value = i;
  // showTwo.value = false;
}
function leaverNav() {
  showTwo.value = false;
  currentShowLight.value = null;
}
function enterTwoNav() {
  currentShowLight.value = currentLeaveShow.value;
  topStyleShow.value = true;
}
function gotoLink(v) {
  router.push(v.path);
  showTwo.value = false;
}

onBeforeRouteUpdate((to) => {
  showTwo.value = false;
  console.log(to);
  store.state.checkName = to.name;
  localStorage.setItem('nowData', store.state.checkName);
});
</script>
<style lang="less" scoped>
li {
  list-style: none;
}

.menu {
}
.twoBox {
  display: flex;
  position: absolute;
  // left: 663px;
  left: 34%;
  top: 50px;
}
.twoUlStyle {
  // width: 200px;
  margin-right: 160px;
}

.twoBoxRightContent {
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 616px;
}

.bg0b64be {
  background-color: #2270ac;
  a {
    color: #fff !important;
  }
}
.twoLiStyle {
  color: #fff;
  margin-bottom: 24px;
  font-size: 16px;
  width: 100px;
  cursor: pointer;
  &:hover {
    color: #fec203 !important;
  }
  color: #fff;
  text-decoration: none;
}
.bg0b64beTopLeave {
  background-color: transparent;
  a {
    color: #333 !important;
  }
}
/* @media screen and (max-width: 1920px) and (max-width: 1470px) {
  .navItem {
    width: 90px !important;
    font-size: 16px !important;
  }
}
 */
@media screen and (max-width: 1470px) {
  .navItem {
    width: 90px !important;
    font-size: 15px !important;
  }
  .twoUlStyle {
    margin-right: 65px !important;
  }
}
@media screen and (max-width: 1199px) {
  .navItem {
    width: 73px !important;
    font-size: 13px !important;
  }
  .twoUlStyle {
    margin-right: 35px !important;
  }
  .twoBox {
    left: 20% !important;
  }
}

.topNav {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  margin: 0;
  padding: 0;
  .navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 0 27px;
    width: 125px;
    text-align: center;
    height: 100%;
    font-size: 18px;
    color: #333;
    cursor: pointer;
    font-weight: 400;
    .liBox {
      width: 100%;
      height: 100%;
      font-size: 18px;
      color: #333;
      cursor: pointer;
      text-align: center;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      color: #333;
      text-decoration: none;
    }
    &:nth-child(1) {
      width: 90px;
    }
    &:nth-child(2) {
      width: 145px;
    }
    &:hover {
      background-color: #2270ac;
      color: #fff;
    }
    &:hover a {
      background-color: #2270ac;
      color: #fff;
    }
  }
}
.bigBox {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 1000000000;
  width: 100%;
  height: 380px;

  background: #2270ac !important;
  // background: rgba(11, 110, 190, 0.95) !important;
  // opacity: 0.95 !important;
}
.bigBox:hover .topNav .navItem {
  background-color: #2270ac;
}
.twoLi {
  position: absolute;
  top: 50px;
}
</style>
