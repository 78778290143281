<template>
  <router-view v-if="isRouterActive" />
</template>
<script setup>
import { ref, provide, nextTick, watch, onMounted  } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
const isRouterActive = ref(true);
const route = useRoute();
provide('reload', () => {
  isRouterActive.value = false;
  nextTick(() => {
    console.log('刷新了页面');
    isRouterActive.value = true;
  });
});
const checkDeviceAndRedirect = () => {
  const mobileWidthThreshold = 1000;
  console.log('window.innerWidth', window.innerWidth);
  
  if (window.innerWidth < mobileWidthThreshold) {
    // 手机端访问时需要跳转到的新网页URL
    console.log('11111111111111');
    
    window.location.href = 'https://www.pzhfzjt.com/mobile/';
  }
};
onMounted(() => {
  // 组件挂载后执行设备检测和跳转
  checkDeviceAndRedirect();
});
</script>
<style lang="less">
.topBannerElCarousel {
  height: 380px;
}
@media screen and (min-width: 1921px) {
  .topBannerElCarousel {
    height: 520px !important;
  }
}

@media screen and (max-width: 1470px) {
  .topBannerElCarousel {
    height: 300px !important;
  }
}
</style>
