import request from "@/utils/request";
export function queryUser(params) {
  return request({
    url: "/api/user/back/selectUserByPage",
    method: "GET",
    params: params,
  });
}
// 编辑角色
export function editUser(data) {
  return request({
    url: "/api/user/back/updateUser",
    method: "PUT",
    data: data,
  });
}
//通过id查询角色详情
export function getUserById(id) {
  return request({
    url: "/api/user/" + id,
    method: "GET",
  });
}
// 新增后台用户
export function addUser(data) {
  return request({
    url: "/api/user/back/addUser",
    method: "post",
    data: data,
  });
}
// 用户修改密码
export function editPassword(data) {
  return request({
    url: "/api/user/password",
    method: "post",
    data: data,
  });
}
