<template>
  <div class="home">
    <el-container class="home_container">
      <el-container class="content">
        <el-header>
          <!--  <div class="topImg">
            <img
              style="width: 100%; height: 100%"
              src="@/assets/imgs/topNav.png"
              alt=""
              @mouseenter="isShow = true"
              @mouseleave="isShow = false"
            />
            <div class="zu" v-if="isShow">
              <img
                src="@/assets/imgs/zu.png"
                style="width: 100%; height: 130px; object-fit: contain"
                alt=""
              />
            </div>
          </div> -->
          <div class="container topClass">
            <div class="logo">
              <!--  <img
                src="@/assets/imgs/headerImg.png"
                style="width: 45px; height: 45px; object-fit: contain"
                alt=""
              /> -->
              <div class="logoRight">
                <img
                  src="@/assets/imgs/headerImg1.png"
                  style="
                    width: 432px;
                    height: 38px;
                    object-fit: contain;
                    cursor: pointer;
                  "
                  alt=""
                  @click="router.push('/')"
                />
                <img
                  src="@/assets/imgs/headerImg2.png"
                  style="width: 432px; height: 16px; object-fit: contain"
                  alt=""
                />
                <!-- <img
                src="@/assets/images/logoName.png"
                style="width: 147px; height: 47px; object-fit: contain"
                alt=""
              /> -->
              </div>
            </div>
            <div class="phone">
              <HomeMenu></HomeMenu>
              <div
                style=""
                class="search"
                @mouseenter="searchShow"
                @mouseleave="searchHide"
              >
                <el-icon><Search /></el-icon>
              </div>
            </div>
          </div>
        </el-header>
        <!-- 搜索 -->
        <!--       -->
        <div class="searchBox">
          <!-- -->
          <el-input
            v-model="searchInput"
            v-if="searchInputShow == 1"
            placeholder="请输入搜索内容"
            class="input_with_select"
            @mouseenter="searchShow1"
            @mouseleave="searchHide1"
          >
            <template #append>
              <div class="searchBtn" @click="gotoSearch">立即搜索</div>
            </template>
          </el-input>
        </div>
        <el-main>
          <router-view></router-view>
          <footer>
            <div class="normalContainer">
              <div class="footerBox">
                <div class="footerleft">
                  <div class="topFooter">
                    <div>地址：四川省攀枝花市东区三线大道北段96号金海大厦</div>
                    <div>电话：0812-3111962</div>
                    <div>邮箱：pzhfazhanjituan@163.com</div>
                  </div>
                  <div class="bottomFooter">
                    <div>
                      <a
                        style="text-decoration: none; color: #fff"
                        href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank"
                        >Copyright©2023 攀枝花发展（控股）集团有限责任公司 |
                        蜀ICP备2023019483号</a
                      >
                    </div>
                    <div style="display: flex; align-items: center">
                      <img
                        style="width: 20px; margin-right: 10px"
                        src="@/assets/imgs/beian.png"
                        alt=""
                      />
                      <a
                        style="text-decoration: none; color: #fff"
                        href="https://beian.mps.gov.cn/"
                        target="_blank"
                        >川公网安备51040202000354号</a
                      >
                    </div>
                  </div>
                </div>
                <div class="footerRight">
                  <img
                    style="width: 70px; height: 70px"
                    src="@/assets/imgs/ewm.jpg"
                    alt=""
                  />
                  <div style="margin-top: 5px">攀发展集团公众号</div>
                </div>
              </div>
            </div>
          </footer>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import HomeMenu from './menuSx.vue';
const route = useRoute();
const router = useRouter();
const store = useStore();
let isShow = ref(false);
let info = reactive({});
let searchInput = ref('');
const activeIndex = ref('/');
let searchInputShow = ref(0);
const infoObj = reactive({
  page: 1,
  pageSize: 10,
});
async function getinfo() {
  const res = await store.dispatch('getinfo/getinfo', infoObj);

  Object.assign(info, res.data);
}
function searchShow() {
  searchInputShow.value = 1;
}
function searchHide() {
  setTimeout(() => {
    if (isinner.value != 1) {
      searchInputShow.value = 0;
    }
  }, 500);
}
function gotoSearch() {
  console.log();
  if (route.path == '/search') {
    router.push({
      query: {
        data: searchInput.value,
      },
    });
  } else {
    router.push('/search?data=' + searchInput.value);
  }
}
let isinner = ref();
function searchShow1() {
  console.log(1123);
  searchInputShow.value = 1;
  isinner.value = 1;
}
function searchHide1() {
  searchInputShow.value = 0;
  isinner.value = 0;
}
onMounted(() => {
  // getinfo();
});
</script>

<style lang="less" scoped>
@media screen and (max-width: 1470px) {
  .logoRight img {
    width: 262px !important;
  }
}
@media screen and (max-width: 1200px) {
  /*  .search {
    margin-left: 0px !important;
  } */
}
/* .searchBox {
  height: 60px;
} */
.searchBox {
  width: 300px;
  position: absolute;
  top: 80px;
  right: 80px;
}
.input_with_select {
  width: 300px;
  height: 70px;

  z-index: 999999999999999;
  border-radius: none;
  margin-top: -10px;
  padding-top: 20px;
  box-sizing: border-box;
}
.searchBtn {
  background-color: #2270ac;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px !important;
  cursor: pointer;
}

/deep/.el-carousel {
  --el-carousel-arrow-font-size: 34px !important;
  --el-carousel-arrow-size: 60px !important;
  --el-carousel-arrow-background: rgba(31, 45, 61, 0.41) !important;
  --el-carousel-arrow-hover-background: rgba(31, 45, 61, 0.63) !important;
}
/deep/.el-carousel__arrow {
  font-weight: bold;
}
.home {
  height: 100%;
  .home_container {
    height: 100%;

    .content {
      .el-header {
        height: 80px;
        padding: 0;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(11, 100, 190, 0.18) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        opacity: 1;
        .topClass {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 80px;
        }
        .topImg {
          position: relative;
          width: 100%;
          height: 100%;
        }
        .zu {
          position: absolute;
          top: 80px;
          left: 50%;
          z-index: 99999999999999;
        }
        .logo {
          display: flex;
          align-items: center;
          margin-right: 30px;
        }
        .logoRight {
          display: flex;
          font-size: 12px;
          color: #333;
          flex-direction: column;
          margin-left: 17px;
          div {
            transform: scale(0.9);
          }
        }
        .phone {
          display: flex;
          align-items: center;
          // justify-content: flex-end;
        }
      }
      .el-main {
        height: 100%;
        padding: 0 !important;
        --el-main-padding: 0;
        box-sizing: border-box;
        // overflow: scroll;
      }
      .routerCK {
        box-sizing: border-box;
        padding: 0 !important;
        margin: 0 auto 100px;
        height: 100%;
        min-width: 1200px;
        width: 1200px;
        background-color: skyblue;
      }
      footer {
        height: 134px;
        background: #2270ac;
        /*  background: linear-gradient(
          180deg,
          rgba(11, 100, 190, 0.18) 0%,
          rgba(255, 255, 255, 0) 100%
        ); */
        color: #fff;
        box-sizing: border-box;

        .footerBox {
          height: 134px;
          // background: #002c59;
          color: #fff;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .footerleft {
          display: flex;
          color: #fff;
          font-size: 14px;
          flex-wrap: wrap;

          .topFooter {
            display: flex;
            div {
              margin-right: 40px;
            }
          }
          .bottomFooter {
            display: flex;
            margin-top: 16px;
            div {
              margin-right: 40px;
            }
          }
        }
        .footerRight {
          width: 250px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
}
.search {
  cursor: pointer;
  margin-left: 30px;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  &:hover {
    background-color: #2270ac;
    color: #fff;
  }
}
@media (max-width: 1199px) {
    .search {
        /* 这里可以添加手机端特有的样式 */
        margin-left: 0px
    }
}
.ell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
}

::v-deep.el-sub-menu__title:hover {
  color: #007bb6 !important;
  background-color: #fff !important;
}
::v-deep.el-sub-menu:hover {
  background-color: #fff !important;
}
</style>
<style lang="less" scoped>
.el-form-item__content {
  height: 32px;
}

::v-deep .el-menu {
  border: none;
  // width: 700px;
}
.el-sub-menu [class^='el-icon-'] {
  width: 40px;
}
::v-deep .el-menu-item {
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
::v-deep .el-sub-menu__title {
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
/* .el-main::-webkit-scrollbar {
  width: 0px;
}
.el-main::-webkit-scrollbar-track {
  background: #fff;
} */
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: #fff;
  color: #007bb6 !important;
}

::v-deep .el-sub-menu .el-sub-menu__icon-arrow {
  position: absolute;
  top: 50%;
  right: var(--el-menu-base-level-padding);
  right: 0px;
  margin-top: -7px;
  transition: transform var(--el-transition-duration);
  font-size: 16px;
  margin-right: 0;
  width: inherit;
}
</style>
<style lang="less">
.el-input-group__append,
.el-input-group__prepend {
  padding: 0 !important;
}
</style>
