import request from '@/utils/request';
export function getBannerAtF() {
  return request({
    url: '/api/banner/f',
    method: 'get',
  });
}
export function getBanner() {
  return request({
    url: '/api/banner',
    method: 'get',
  });
}
export function putBanner(data) {
  return request({
    url: '/api/banner',
    method: 'put',
    data,
  });
}

export function addBanner(data) {
  return request({
    url: '/api/banner',
    method: 'post',
    data,
  });
}
export function deleteBanner(data) {
  return request({
    url: '/api/banner/' + data,
    method: 'delete',
  });
}
