import request from '@/utils/request';

// 获取产品类型
export function getProductType() {
  return request({
    url: '/api/ptype/front',
    method: 'GET',
  });
}
// 获取当前类型的所有文章
export function getProductByTypeId(e) {
  console.log(e);
  return request({
    url: '/api/article/f/getByType/' + e.type,
    method: 'GET',
    params: e,
  });
}
// 通过文章id获取详情
export function getArticleById(id) {
  console.log(id);
  return request({
    url: '/api/article/f/getById/' + id,
    method: 'GET',
  });
}
