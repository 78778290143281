import { queryMenu } from "@/api/system/menu";
import { addMenu } from "@/api/system/menu";
import { editMenu } from "@/api/system/menu";
import { queryMenuById } from "@/api/system/menu";
import { deleteMenu } from "@/api/system/menu";
import { queryParMenu } from "@/api/system/menu";
const actions = {
  queryMenu({ commit }) {
    return new Promise((resolve, reject) => {
      queryMenu()
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addMenu({ commit }, data) {
    return new Promise((resolve, reject) => {
      addMenu(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editMenu({ commit }, data) {
    return new Promise((resolve, reject) => {
      editMenu(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryMenuById({ commit }, id) {
    return new Promise((resolve, reject) => {
      queryMenuById(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteMenu({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteMenu(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryParMenu({ commit }) {
    return new Promise((resolve, reject) => {
      queryParMenu()
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
