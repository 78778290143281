import { uploads } from "@/api/upload";
const actions = {
  uploads({ commit }, data) {
    return new Promise((resolve, reject) => {
      uploads(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
