import {
  queryNewsPage,
  addNews,
  queryNewsById,
  editNews,
  deleteNews,
  queryArticleAllPage,
} from "@/api/Lnformation/news";
const actions = {
  queryNewsPage({ commit }, e) {
    return new Promise((resolve, reject) => {
      queryNewsPage(e)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryArticleAllPage({ commit }, params) {
    return new Promise((resolve, reject) => {
      queryArticleAllPage(params)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addNews({ commit }, e) {
    return new Promise((resolve, reject) => {
      addNews(e)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryNewsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      queryNewsById(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editNews({ commit }, id) {
    return new Promise((resolve, reject) => {
      editNews(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteNews({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteNews(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
