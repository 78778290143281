import {
  getBanner,
  putBanner,
  addBanner,
  deleteBanner,
  getBannerAtF,
} from '@/api/Lnformation/banner';
const actions = {
  getBannerAtF({ commit }) {
    return new Promise((resolve, reject) => {
      getBannerAtF()
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBanner({ commit }) {
    return new Promise((resolve, reject) => {
      getBanner()
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  putBanner({ commit }, data) {
    return new Promise((resolve, reject) => {
      putBanner(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addBanner({ commit }, data) {
    return new Promise((resolve, reject) => {
      addBanner(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteBanner({ commit }, data) {
    return new Promise((resolve, reject) => {
      deleteBanner(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
