import { getLeaderInfo } from '@/api/leaders';
const actions = {
  getLeaderInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getLeaderInfo(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
