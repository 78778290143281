import axios from 'axios';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import LocalCache from '@/utils/cache';

let loading = null;

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
});
service.interceptors.request.use(
  (config) => {
    loading = ElLoading.service({
      lock: true,
      text: '数据加载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    });
    if (LocalCache.getCache('token')) {
      // config.headers.token = LocalCache.getCache("token");
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 200 && res.code !== undefined) {
      ElMessage({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000,
      });
      loading.close();
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      loading.close();
      return res;
    }
  },
  (error) => {
    loading.close();
    console.log('err' + error);
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
