import { queryUser } from "@/api/system/user";
import { editUser } from "@/api/system/user";
import { getUserById } from "@/api/system/user";
import { addUser } from "@/api/system/user";
import { editPassword } from "@/api/system/user";
const actions = {
  queryUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      queryUser(params)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      editUser(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUserById({ commit }, id) {
    return new Promise((resolve, reject) => {
      getUserById(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      addUser(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      editPassword(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
