import { articAudit } from "@/api/audit/index";
const actions = {
  articAudit({ commit }, id) {
    return new Promise((resolve, reject) => {
      articAudit(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
