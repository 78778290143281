import request from "@/utils/request";
// 获取所有菜单
export function queryMenu() {
  return request({
    url: "/api/sysCarte",
    method: "GET",
  });
}
// 新增菜单
export function addMenu(data) {
  return request({
    url: "/api/sysCarte",
    method: "POST",
    data: data,
  });
}
// 编辑菜单
export function editMenu(data) {
  return request({
    url: "/api/sysCarte",
    method: "PUT",
    data: data,
  });
}
// 通过id查看菜单
export function queryMenuById(id) {
  return request({
    url: "/api/sysCarte/" + id,
    method: "GET",
  });
}
// 通过id删除菜单
export function deleteMenu(id) {
  return request({
    url: "/api/sysCarte/" + id,
    method: "DELETE",
  });
}
// 获取父菜单
export function queryParMenu() {
  return request({
    url: "/api/sysCarte/parent",
    method: "GET",
  });
}
