import request from "@/utils/request";
// 分页查询角色
export function queryRole(params) {
  return request({
    url: "/api/sysRole",
    method: "GET",
    params: params,
  });
}
// 查询所有角色
export function queryRoleAll() {
  return request({
    url: "/api/sysRole",
    method: "GET",
    params: {
      page: 1,
      size: 999,
    },
  });
}
// 新增角色
export function addRole(data) {
  return request({
    url: "/api/sysRole",
    method: "POST",
    data: data,
  });
}
// 通过id查询角色
export function queryRoleById(id) {
  return request({
    url: "/api/sysRole/" + id,
    method: "GET",
  });
}
// 编辑角色
export function editRole(data) {
  return request({
    url: "/api/sysRole",
    method: "PUT",
    data: data,
  });
}
// 删除角色
export function deleteRole(id) {
  return request({
    url: "/api/sysRole/" + id,
    method: "DELETE",
  });
}
