import {
  getProductType,
  getProductByTypeId,
  getArticleById,
} from '@/api/article/index';
const actions = {
  getProductType({ commit }) {
    return new Promise((resolve, reject) => {
      getProductType()
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getProductByTypeId({ commit }, e) {
    console.log(e);
    return new Promise((resolve, reject) => {
      getProductByTypeId(e)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getArticleById({ commit }, id) {
    return new Promise((resolve, reject) => {
      getArticleById(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
