import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import HomeView from '../views/Home/HomeView.vue';

const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '/',
        name: '首页',
        component: () => import('@/views/index/index'),
        children: [],
      },

      {
        path: '/walktoDevelop',
        name: '走进攀发展',
        hidden: false,
        component: () => import('@/views/walktoDevelop/index'),
        children: [
          {
            path: '/walktoDevelop',
            hidden: false,
            name: '集团简介',
            component: () => import('@/views/walktoDevelop/jtjj'),
            children: [],
          },
          {
            path: '/corporateCulture',
            hidden: false,
            name: '企业文化',
            component: () => import('@/views/walktoDevelop/corporateCulture'),
            children: [],
          },
          {
            path: '/leaders',
            hidden: false,
            name: '领导班子',
            component: () => import('@/views/walktoDevelop/leaders'),
            children: [],
          },
          {
            path: '/sonCompany',
            hidden: false,
            name: '市属国有企业',
            component: () => import('@/views/walktoDevelop/sonCompany'),
            children: [],
          },
        ],
      },

      {
        path: '/ddjsgzdt',
        hidden: false,
        name: '党的建设',
        children: [
          {
            path: '/ddjsgzdt',
            name: '工作动态 ',
            hidden: false,
            component: () => import('@/views/ddjs/gzdt'),
            children: [],
          },
          {
            path: '/ffcl',
            name: '党风廉政建设',
            hidden: false,
            component: () => import('@/views/ffcl/ffcl'),
            children: [],
          },
          {
            path: '/xxyd',
            name: '学习园地',
            hidden: false,
            component: () => import('@/views/ddjs/xxyd'),
            children: [],
          },
        ],
        component: () => import('@/views/ddjs/NewIndex'),
      },
      {
        path: '/news',
        hidden: false,
        name: '新闻动态',
        children: [
          /*  {
            path: '/news',
            name: '新闻动态',
            hidden: false,
            component: () => import('@/views/news/newsList'),
            children: [],
          }, */
        ],
        component: () => import('@/views/news/NewIndex'),
      },
      {
        path: '/gsgg',
        hidden: false,
        name: '公示公告',
        // component: () => import('@/views/walktoDevelop/index'),
        component: () => import('@/views/publicity/NewIndex'),
        children: [
          {
            path: '/gsgg',
            name: '集团公示公告',
            hidden: false,
            component: () => import('@/views/publicity/gsgg'),
            children: [],
          },
          {
            path: '/ztb',
            name: '招投标',
            hidden: false,
            component: () => import('@/views/publicity/ztb'),
            children: [],
          },
        ],
      },
      {
        path: '/shzr',
        hidden: false,
        name: '企业规划',
        children: [
          /*  {
            path: '/qygh',
            name: '企业规划',
            component: () => import('@/views/qygh/qyghList'),
            children: [],
          }, */
          {
            path: '/shzr',
            name: '社会责任',
            component: () => import('@/views/ztzl/shzr'),
            children: [],
          },
        ],
        component: () => import('@/views/qygh/NewIndex'),
      },
      {
        path: '/yfzq',
        hidden: false,
        name: '依法治企',
        component: () => import('@/views/ztzl/NewIndex'),
        children: [
          /* {
            path: '/yfzq',
            name: '依法治企',
            component: () => import('@/views/ztzl/yfzq'),
            children: [],
          }, */
        ],
      },
      {
        path: '/rcfzgzdt',
        hidden: false,
        name: '人才发展',
        children: [
          {
            path: '/rcfzgzdt',
            name: '工作动态',
            component: () => import('@/views/rcfz/gzdt'),
            children: [],
          },
          {
            path: '/rcln',
            name: '人才理念',
            component: () => import('@/views/rcfz/rcln'),
            children: [],
          },
          {
            path: '/rcpy',
            name: '人才培养',
            component: () => import('@/views/rcfz/rcpy'),
            children: [],
          },
          {
            path: '/rczp',
            name: '人才招聘',
            component: () => import('@/views/rcfz/rczp'),
            children: [],
          },
        ],
        component: () => import('@/views/rcfz/NewIndex'),
      },
      {
        path: '/ffcl',
        hidden: true,
        name: '反腐倡廉',
        children: [],
        component: () => import('@/views/ffcl/index'),
      },
      /*  {
        path: '/contactUs',
        hidden: false,
        name: '联系我们',
        children: [],
        component: () => import('@/views/contactUs/index'),
      }, */
      {
        path: '/details',
        hidden: true,
        name: '详情页',
        component: () => import('@/components/articleDetails/index.vue'),
      },
      {
        path: '/search',
        hidden: true,
        name: '搜索结果',
        component: () => import('@/views/ddjs/searchPage'),
        children: [],
      },
      /* {
        path: '/caseDetails',
        name: 'caseDetails',
        hidden: true,
        component: () => import('@/views/case/caseDetails'),
      }, */
    ],
  },

  // {
  //   path: '/:pathMatch(.*)*',

  //   component: () => import('@/views/notFound/index.vue'),
  // },
];

const router = createRouter({
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 };
    }
  },
  history: createWebHistory(),
  routes,
});

export default router;
