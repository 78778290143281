import {
  getproduct,
  getproduct2,
  getproductLX,
  addproductLX,
  putproductLX,
  deleteproductLX,
  addArtcle,
  putArtcle,
  deleteArtcle,
  putArtcleStatus,
  getCaseDetails,
} from '@/api/Lnformation/product';
const actions = {
  getCaseDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      getCaseDetails(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getproduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      getproduct(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getproduct2({ commit }, data) {
    return new Promise((resolve, reject) => {
      getproduct2(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getproductLX({ commit }, data) {
    return new Promise((resolve, reject) => {
      getproductLX(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addproductLX({ commit }, data) {
    return new Promise((resolve, reject) => {
      addproductLX(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  putproductLX({ commit }, data) {
    return new Promise((resolve, reject) => {
      putproductLX(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteproductLX({ commit }, data) {
    return new Promise((resolve, reject) => {
      deleteproductLX(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addArtcle({ commit }, data) {
    return new Promise((resolve, reject) => {
      addArtcle(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  putArtcle({ commit }, data) {
    return new Promise((resolve, reject) => {
      putArtcle(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteArtcle({ commit }, data) {
    return new Promise((resolve, reject) => {
      deleteArtcle(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  putArtcleStatus({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      putArtcleStatus(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
