import { login } from "@/api/login";
const actions = {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      login(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
