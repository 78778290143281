import { queryRole } from "@/api/system/role";
import { queryRoleAll } from "@/api/system/role";
import { addRole } from "@/api/system/role";
import { queryRoleById } from "@/api/system/role";
import { editRole } from "@/api/system/role";
import { deleteRole } from "@/api/system/role";
const actions = {
  queryRole({ commit }, params) {
    return new Promise((resolve, reject) => {
      queryRole(params)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryRoleAll({ commit }) {
    return new Promise((resolve, reject) => {
      queryRoleAll()
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addRole({ commit }, data) {
    return new Promise((resolve, reject) => {
      addRole(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  queryRoleById({ commit }, id) {
    return new Promise((resolve, reject) => {
      queryRoleById(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editRole({ commit }, data) {
    return new Promise((resolve, reject) => {
      editRole(data)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteRole({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteRole(id)
        .then((success) => {
          resolve(success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
