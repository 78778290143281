import { createStore } from 'vuex';
// 登录
import login from './modules/login';
// 用户
import queryUser from './modules/system/user';
import editUser from './modules/system/user';
import getUserById from './modules/system/user';
import addUser from './modules/system/user';
import editPassword from './modules/system/user';
// 角色
import queryRole from './modules/system/role';
import queryRoleAll from './modules/system/role';
import addRole from './modules/system/role';
import queryRoleById from './modules/system/role';
import editRole from './modules/system/role';
import deleteRole from './modules/system/role';
// 菜单
import queryMenu from './modules/system/menu';
import addMenu from './modules/system/menu';
import editMenu from './modules/system/menu';
import queryMenuById from './modules/system/menu';
import deleteMenu from './modules/system/menu';
import queryParMenu from './modules/system/menu';

import getinfo from './modules/index/index';
import getlist from './modules/index/index';
import getQyInfo from './modules/index/index';
import getdetail from './modules/index/index';
import getbanner from './modules/index/index';
import searchinfo from './modules/index/index';

import getProductType from './modules/article/index';
import getProductByTypeId from './modules/article/index';
import getArticleById from './modules/article/index';
// 访问记录
import getPie from './modules/report/index';
import getMap from './modules/report/index';

// 图文管理
import putinfo from './modules/Lnformation/info';
import getinfo1 from './modules/Lnformation/info';
import queryNewsPageIndex from './modules/Lnformation/info';

// 上传
import uploads from './modules/upload/index';

// 轮播图
import getBanner from './modules/Lnformation/banner';
import putBanner from './modules/Lnformation/banner';
import addBanner from './modules/Lnformation/banner';
import deleteBanner from './modules/Lnformation/banner';
import getBannerAtF from './modules/Lnformation/banner';
// 产品
import getproduct from './modules/Lnformation/product';
import getproduct2 from './modules/Lnformation/product';
import getproductLX from './modules/Lnformation/product';
import addproductLX from './modules/Lnformation/product';
import putproductLX from './modules/Lnformation/product';
import deleteproductLX from './modules/Lnformation/product';
import addArtcle from './modules/Lnformation/product';
import putArtcle from './modules/Lnformation/product';
import deleteArtcle from './modules/Lnformation/product';
import putArtcleStatus from './modules/Lnformation/product';
import getCaseDetails from './modules/Lnformation/product';

// 文章管理
import queryNewsPage from './modules/Lnformation/news';
import addNews from './modules/Lnformation/news';
import queryNewsById from './modules/Lnformation/news';
import editNews from './modules/Lnformation/news';
import deleteNews from './modules/Lnformation/news';
import queryArticleAllPage from './modules/Lnformation/news';

// 审核
import articAudit from './modules/audit/index';

// 领导
import getLeaderInfo from './modules/leaders/index';

export default createStore({
  state: {
    checkName: '',
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    queryUser,
    editUser,
    getUserById,
    addUser,
    editPassword,
    queryRole,
    queryRoleAll,
    addRole,
    queryRoleById,
    editRole,
    deleteRole,
    queryMenu,
    addMenu,
    editMenu,
    queryMenuById,
    deleteMenu,
    queryParMenu,
    getPie,
    getMap,
    putinfo,
    getinfo1,
    getinfo,
    getlist,
    getQyInfo,
    getdetail,
    getbanner,
    searchinfo,
    getProductType,
    getProductByTypeId,
    getArticleById,
    uploads,
    getBanner,
    putBanner,
    addBanner,
    deleteBanner,
    getBannerAtF,
    getproduct,
    getproductLX,
    addproductLX,
    putproductLX,
    deleteproductLX,
    addArtcle,
    putArtcle,
    deleteArtcle,
    putArtcleStatus,
    getproduct2,
    queryNewsPage,
    addNews,
    queryNewsById,
    editNews,
    deleteNews,
    queryArticleAllPage,
    articAudit,
    getCaseDetails,
    queryNewsPageIndex,
    getLeaderInfo,
  },
});
