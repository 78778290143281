import request from '@/utils/request';

export function getproduct(params) {
  return request({
    url: '/api/article/f/getByType/1',
    method: 'get',
    params,
  });
}
export function getproduct2(params) {
  return request({
    url: '/api/article/f/getByType/2',
    method: 'get',
    params,
  });
}
export function getproductLX(params) {
  return request({
    url: '/api/ptype',
    method: 'get',
    params,
  });
}

export function addproductLX(data) {
  return request({
    url: '/api/ptype',
    method: 'post',
    data,
  });
}
export function putproductLX(data) {
  return request({
    url: '/api/ptype',
    method: 'put',
    data,
  });
}
export function deleteproductLX(params) {
  return request({
    url: '/api/ptype',
    method: 'delete',
    params,
  });
}
export function addArtcle(data) {
  return request({
    url: '/api/article',
    method: 'post',
    data,
  });
}
export function putArtcle(data) {
  return request({
    url: '/api/article',
    method: 'put',
    data,
  });
}
export function deleteArtcle(data) {
  return request({
    url: '/api/article/' + data,
    method: 'delete',
  });
}
export function putArtcleStatus(data) {
  return request({
    url: '/api/article/status/' + data,
    method: 'put',
  });
}
export function getCaseDetails(params) {
  return request({
    url: '/api/article/f/getById/' + params,
    method: 'get',
  });
}
