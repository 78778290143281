import request from '@/utils/request';
export function putinfo(data) {
  return request({
    url: '/api/article/index',
    method: 'put',
    data,
  });
}
export function getinfo(params) {
  return request({
    url: '/api/article/f/getByType/5',
    method: 'get',
    params,
  });
}
export function queryNewsPageIndex() {
  return request({
    url: '/api/article/f/index',
    method: 'GET',
  });
}
